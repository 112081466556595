<template>
      <div class="customPagination">
        <div class="customPagination-container">
            <el-button plain :disabled="currentPage <= 1" @click="change(currentPage-1)">上一页</el-button>
            <el-button
                v-for="page in pageList"
                :key="page"
                :type="page == currentPage ? 'primary' : ''"
                plain
                @click="change(page)">
                {{ page }}
            </el-button>
            <el-button plain :disabled="currentPage >= this.totalPages" @click="change(currentPage+1)">下一页</el-button>
            <!-- <span class="customPagination-container-item"  :class="{ active: page == currentPage }">
            {{ page }}
            </span> -->
        </div>
      </div>
  </template>
  
  <script>
  export default {
    name: 'customPagination',
    props: {
      total: {
        type: Number,
        default: 0
      },
      currentPage: {
        type: Number,
        default: 0
      },
      pageSize: {
        type: Number,
        default: 10
      },
      defaultPages: {
        type: Number,
        default: 10
      }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total / this.pageSize)
        },
        pageList() {
            const { total, currentPage, pageSize, defaultPages } = this;
            const totalPages = Math.ceil(total / pageSize);
    
            // 如果总页数小于或等于默认显示的页数，则显示所有页码
            if (totalPages <= defaultPages) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
            }
    
            // 计算当前页在数组中的位置
            const middleIndex = Math.floor(defaultPages / 2);
            let startPage = Math.max(1, currentPage - middleIndex);
            let endPage = Math.min(totalPages, startPage + defaultPages - 1);
    
            // 调整起始页和结束页，确保显示的页码数量为defaultPages
            if (endPage - startPage + 1 < defaultPages) {
            if (startPage > 1) {
                startPage = Math.max(1, endPage - defaultPages + 1);
            } else if (endPage < totalPages) {
                endPage = Math.min(totalPages, startPage + defaultPages - 1);
            }
            }
    
            // 生成页码列表
            return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
        }
    },
    methods: {
        change(page) {
            if (this.currentPage == page) return
            this.$emit('update:currentPage', page)
            this.$emit('current-change', page)
        }
    }
  };
  </script>
  
  <style lang="less" scoped>
    .customPagination{
        display: flex;
        align-items: center;
        justify-content: center;
        &-container{
            display: flex;
            align-items: center;
            .el-button{
                transition: none;
                &.el-button--primary{
                    background-color: #1C68FF;
                    color: #fff;
                }
            }
        }
    }
  </style>